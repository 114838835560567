<template>
  <div
    id="id_imas"
    class="mt-4"
  >
    <div class="container">

      <b-row class="mb-4">

        <template v-for="item in $t('solutions')">
          <b-col
            :key="item.title"
            class="mb-4"
            xl="4"
            lg="6"
            md="6"
            style="height: 35vh;"
          >

            <a
              v-if="item.link"
              @click="openLink(item.link)"
            >

              <commit
                :imas-title="item.title"
                :image="item.image"
                :icon="item.icon"
              />

            </a>

            <commit
              v-else
              :imas-title="item.title"
              :image="item.image"
              :icon="item.icon"
            />

          </b-col>
        </template>
      </b-row>
    </div>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
} from 'bootstrap-vue'

import Commit from 'Components/AboutUs/Commit.vue'

import MyData from 'Data/solutions.json'

export default {
  name: 'Imas',
  components: {
    BRow,
    BCol,
    Commit,
  },
  data() {
    return {
      data: MyData.data,
    }
  },
  methods: {
    openLink(link) {
      window.open(link, '_self', 'noreferrer')
    },
  },
}
</script>

<style scoped>

a {
  font-size: 22px;
  font-weight: bold;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

</style>
