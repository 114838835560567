<template>
  <div>

    <!-- Banner -->
    <banner
      :video="data.bannerVideo"
      :image="data.bannerImage"
      :title="$t('smartSolutions.bannerTitle')"
      height="300"
      solutions
    />

    <!-- IMAS -->
    <imas />

    <!-- HELP -->
    <help />

    <div class="container my-3">
      <div class="row justify-content-center text-center">
        <div class="block-head w-100">
          <p>
            {{ $t('help.text') }}
          </p>

          <p>
            {{ $t('help.end') }}
          </p>

          <b-button
            class="mb-3"
            :href="$t('help.form')"
            variant="primary"
            pill
            target="_blank"
            rel="noopener"
          >
            {{ $t('help.button') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from 'Components/Common/Banner.vue'
import Imas from 'Components/Solutions/Imas.vue'
import Help from 'Components/Solutions/Help.vue'
import { BButton } from 'bootstrap-vue'
import MyData from 'Data/solutions.json'

export default {
  name: 'Solutions',
  components: {
    Banner,
    Imas,
    Help,
    BButton,
  },
  data() {
    return {
      data: MyData.data,
    }
  },
}
</script>
