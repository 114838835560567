<template>
  <div class="container-help">
    <b-row>
      <b-col
        lg="12"
        xs="12"
        class="d-flex align-items-center justify-content-center"
      >
        <!-- Title -->
        <h2>
          {{ $t('help.title') }}
        </h2>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  name: 'Help',
  components: {

    BRow,
    BCol,
  },
}
</script>

<style scoped>

  .container-help {
    background-color: #ff8f00;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  h2 {
      font-size: 45px !important;
      text-transform: uppercase;
      text-align: center !important;
   }

  @media (max-width: 700px) {
   h2 {
      font-size: 25px !important;
   }
}

</style>
